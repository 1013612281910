<template>
  <div>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Input placeholder="请输入店铺名称,用户姓名,手机号" v-model="storePrams.name"/>
        </div>
      </Col>
      <Col span="4">
        <div>
          <DatePicker placeholder="时间查询" v-model="storePrams.create_time" style="width: 100%;"></DatePicker>
        </div>
      </Col>
      <Col span="6">
        <div>
          <Button type="primary" icon="ios-search-outline" @click="searchList">搜索</Button>
        </div>
      </Col>
    </Row>
    <Row :gutter="16" style="margin-bottom: 24px">
      <Col span="6">
        <div>
          <Button icon="md-add" type="primary" v-if="jurisdictionList.indexOf('admin-store-create') !== -1"
                  @click="addNotice('add')">创建
          </Button>
        </div>
      </Col>
    </Row>
    <Table border :columns="platformColumns" :data="storeList">
      <template slot-scope="{ row, index }" slot="operation">
        <!-- <Button type="success" ghost size="small" style="margin-right: 5px" @click="same(row)">同步</Button> -->
        <Button type="info" ghost size="small" style="margin-right: 5px" @click="changeMsg(row)">次数修改</Button>
        <Button v-if="jurisdictionList.indexOf('admin-store-update') !== -1" type="primary" size="small"
                style="margin-right: 5px" @click="edit(index,'edit',row)">编辑
        </Button>
        <Button v-if="jurisdictionList.indexOf('platform-delete') !== -1" type="error" size="small"
                style="margin-right: 5px" @click="clearDel(row.id)">删除
        </Button>
        <Button v-if="jurisdictionList.indexOf('platform-configure') !== -1" size="small"
                @click="changeConfigure(index,row)" style="margin-right: 5px">配置权限
        </Button>
        <Button type="info" size="small" @click="getIn(row)">进入平台</Button>
      </template>
      <template slot-scope="{ row }" slot="status">
        <i-switch @on-change="changeOn(row)" v-model="row.it_disabled" size="large" :true-value="0" :false-value="1">
          <span slot="open">启用</span>
          <span slot="close">禁用</span>
        </i-switch>
      </template>
      <template slot-scope="{ row }" slot="balance">
        <Button @click="balanceBtn(row.id)">{{row.mt_amount}}</Button>
      </template>
      <template slot-scope="{ row }" slot="identity">
        <div style="display: flex;align-items: center">
          <div style="margin-right: 5px" v-if="row.identity=== 1">个人</div>
          <div style="margin-right: 5px" v-if="row.identity=== 2">企业</div>
        </div>
      </template>
      <template slot-scope="{ row }" slot="contract">
        <div>{{ row.contracts_num }} <span style="color: #00a6ff">({{ row.contracts_usenum || 0 }})</span></div>
      </template>
      <template slot-scope="{ row }" slot="notContract">
        <div>{{ row.not_contracts_num }} <span style="color: #00a6ff">({{ row.not_contracts_usenum || 0 }})</span></div>
      </template>
      <template slot-scope="{ row }" slot="message">
        <div>{{ row.message_num }} <span style="color: #00a6ff">({{ row.message_usenum || 0 }})</span></div>
      </template>
      <template slot-scope="{ row,index }" slot="storeName">
        <Button type="success" size="small"  @click="handleCopy(index,row.link_url)" >{{ row.un_name }}</Button>
      </template>
      <template slot-scope="{ index }" slot="index">
        <div>{{index + 1}}</div>
      </template>
    </Table>
    <br/>
    <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total
          @on-change="pageChange"
          :page-size="storePrams.limit"/>
    <Modal
        title="配置权限"
        v-model="configure"
        :mask-closable="false"
        width="500"
        @on-ok="submitConfigure"
        @on-cancel="cancelConfigure"
        class-name="vertical-center-modal">
      <Form :model="formConfigure" :label-width="120" style="width: 450px">
        <div>系统配置</div>
        <FormItem label="OEM配置" v-if="jurisdictionList.indexOf('admin-platform-configure-oem') !== -1">
          <i-switch v-model="formConfigure.oem_status" size="large" @on-change="changeThird">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
<!--        <FormItem label="云储配置" v-if="jurisdictionList.indexOf('admin-platform-configure-cloud') !== -1">-->
<!--          <i-switch v-model="formConfigure.cloud_status" size="large" @on-change="changeFirst">-->
<!--            <span slot="open">开启</span>-->
<!--            <span slot="close">关闭</span>-->
<!--          </i-switch>-->
<!--        </FormItem>-->
<!--        <FormItem label="短信签名">-->
<!--          <Input v-model="formConfigure.synopsis" type="textarea" :autosize="{minRows: 2,maxRows: 5}"-->
<!--                 placeholder="请输入短信签名"></Input>-->
<!--        </FormItem>-->
         <FormItem label="短信配置" v-if="jurisdictionList.indexOf('admin-platform-configure-sms') !== -1">
          <i-switch v-model="formConfigure.sms_status" size="large"
                    @on-change="changeSecond">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <div>插件管理</div>
        <FormItem v-for="(item,index) of PluginList" :key="index" :label="item.title">
          <i-switch :value="plugin_ids.indexOf(item.id)!=-1?true:false" size="large" @on-change="pluginunit($event,item.id)">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <!-- <FormItem label="收银">
          <i-switch v-model="formConfigure.sy_status" size="large"
                    @on-change="pluginunit($event,4)">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem label="排队取号">
          <i-switch v-model="formConfigure.pd_status" size="large"
                    @on-change="pluginunit($event,5)">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem label="积分商城">
          <i-switch v-model="formConfigure.jf_status" size="large"
                    @on-change="pluginunit($event,6)">
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem> -->
      </Form>
    </Modal>
    <Modal
        :title="formTitle"
        v-model="addModel"
        :mask-closable="false"
        width="600"
        :footer-hide="true"
        class-name="vertical-center-modal"
        @on-ok="submit"
        @on-cancel="cancel">
      <Form ref="formItem" :rules="ruleValidate" :model="platform" :label-width="120" style="width: 500px">
        <FormItem label="账户" prop="account">
          <Input v-model="platform.account" placeholder="请输入账户"></Input>
        </FormItem>
        <FormItem label="密码" prop="password">
          <Input v-model="platform.password" type="password" placeholder="请输入密码"></Input>
        </FormItem>
        <FormItem label="用户手机号" prop="phone">
          <Input v-model="platform.phone" placeholder="请输入用户手机号"></Input>
        </FormItem>
        <FormItem label="用户姓名" prop="contacts_name">
          <Input v-model="platform.contacts_name" placeholder="请输入用户姓名"></Input>
        </FormItem>
        <FormItem label="平台名称" prop="un_name">
          <Input v-model="platform.un_name" placeholder="请输入平台名称"></Input>
        </FormItem>
        <FormItem label="域名" prop="remote_domain">
          <Input v-model="platform.remote_domain" placeholder="请输入域名"></Input>
        </FormItem>
        <FormItem label="平台有效期">
          <Select v-model="timeChange" placeholder="请选择公告类型" @on-change="changeType">
            <Option value='0'>永久</Option>
            <Option value="TIME">选择时间</Option>
          </Select>
        </FormItem>
        <FormItem v-if="timeChange === 'TIME'">
          <DatePicker type="date" v-model="platform.date" placeholder="请选择平台有效期" style="width: 380px"></DatePicker>
        </FormItem>
        <FormItem label="店铺模式">
          <Select v-model="platform.store_mode" placeholder="请选择店铺模式">
            <Option :value='1'>多店</Option>
            <Option :value='2'>多账户</Option>
            <Option :value='3'>综合</Option>
          </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleSubmit('formItem')">确认</Button>
          <Button @click="handleReset('formItem')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
    <Modal
        title="编辑次数"
        v-model="editMessage"
        :mask-closable="false"
        width="600"
        :footer-hide="true"
        class-name="vertical-center-modal">
      <Form ref="messageForm" :rules="ruleMessageForm" :model="messageForm" :label-width="140" style="width: 500px">
        <FormItem label="店铺数量" prop="shop_num">
          <div class="just_center">
            <Input style="width:320px" v-model="messageForm.shop_num" type="number" placeholder="请输入店铺数量"></Input>
            <Icon @click="same" class="refoIcon" type="md-refresh" />
          </div>
        </FormItem>
<!--        <FormItem label="未认证合同次数" prop="not_contract_number">-->
<!--          <Input v-model="messageForm.not_contract_number" type="number" placeholder="请输入合同次数"></Input>-->
<!--        </FormItem>-->
        <FormItem label="短信数量" prop="message_num">
          <Input style="width:320px" v-model="messageForm.message_num" type="number" placeholder="请输入短信次数"></Input>
        </FormItem>
        <FormItem>
          <Button type="primary" @click="handleMessage('messageForm')">确认</Button>
          <Button @click="resetMessage('messageForm')" style="margin-left: 8px">重置</Button>
        </FormItem>
      </Form>
    </Modal>
    <Modal
        v-model="samemodal"
        title="信息同步"
        :mask-closable="false"
        @on-ok="same"
        @on-cancel="samecancel">
        确认信息同步吗？
    </Modal>
    <Modal
        :mask-closable='false'
        width="800"
        v-model="balancemodal"
        title="美团余额变动记录">
        <Form :label-width="100">
            <FormItem label="当前余额：">
              <div style="display:flex">
                <div class="num_Txt">{{amount}}</div>
                <div style="margin-left:20px"><Button @click="changecli">变动</Button></div>
              </div>
            </FormItem>
            <FormItem label="日期选择：">
              <div style="display:flex">
                <div><DatePicker @on-change="time1" type="daterange" placement="bottom-start" placeholder="点击选择日期" style="width: 200px"></DatePicker></div>
                <div style="margin-left:20px"><Button @click="searchBtn1" class="btn">搜索</Button></div>
              </div>
            </FormItem>
        </Form>
        <Table ref="selection" stripe :columns="balanceColumns" :data="data5">
          <template slot-scope="{ index }" slot="index">
            <div>{{index+1}}</div>
          </template>
          <template slot-scope="{ row }" slot="scene">
            <div v-if="row.scene==1">管理员操作</div>
            <div v-if="row.scene==2">平台操作</div>
            <!-- <div v-if="row.scene==1001">订单退款</div>
            <div v-if="row.scene==1002">商家操作</div> -->
          </template>
        </Table>
        <br/>
        <Page :total="total1"
              :current="balancedata.page"
              style="text-align:right"
              show-elevator
              show-total
              @on-change="pageChange1"
              :page-size="balancedata.limit"/>
        <div slot="footer">
            <Button @click="balancemodal = false" class="btn">关闭</Button>
        </div>
    </Modal>
    <Modal
        :mask-closable='false'
        v-model="changemodal"
        title="美团余额变动"
        @on-cancel="changecancel">
        <Form :label-width="100">
            <FormItem label="当前余额：">
              <div>{{amount}}</div>
              <div>
                <RadioGroup v-model="balance.type">
                    <Radio :label="1">增加</Radio>
                    <Radio :label="2">减少</Radio>
                </RadioGroup>
              </div>
            </FormItem>
            <FormItem label="余额变动：">
              <Input v-model="balance.val" placeholder="请输入余额"></Input>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button @click="changecancel">取消</Button>
            <Button type="primary" @click="changeadd" class="btn">确认</Button>
        </div>
    </Modal>

  </div>
</template>
<script>
import {
  storeList,
  storeBan,
  storeAdd,
  storeDel,
  storeInfo,
  storeUpdate,
  storeSetup,
  storeGetin,
  storeUpdateScnum,
  storeSynchro,
  mtAmountList,
  getMtAmount,
  mtAmountHandle,
  getPlugin
} from '../../api/platform'
import moment from "moment";

export default {
  data() {
    return {
      platformColumns: [
        {
          title: '#',
          slot: 'index',
          width: 80,
        },
        {
          title: '平台ID',
          key: 'id',
          width: 80,
        },
        {
          title: '平台名称',
          key: 'un_name',
          slot:'storeName',
          width: 180,
        },
        {
          title: '域名',
          key: 'remote_domain',
          width: 180,
        },
        {
          title: '账户',
          key: 'account',
          width: 180,
        },
        {
          title: '用户姓名',
          key: 'contacts_name',
          width: 180,
        },
        {
          title: '手机号',
          key: 'phone',
          width: 180,
        },
        {
          title: '有效期',
          key: 'effective_time',
          width: 180,
        },
        {
          title: '平台创建时间',
          key: 'created_at',
          width: 180,
        },
        {
          title: '平台状态',
          key: 'it_disabled',
          slot: 'status',
          width: 100,
        },
        {
          title: '美团余额',
          slot: 'balance',
          width: 100,
        },
        {
          title: '店铺数量',
          key: 'shop_num',
          width: 150,
        },
        {
          title: '短信数量',
          key: 'message_num',
          width: 100,
          slot: 'message'
        },
        // {
        //   title: '登录链接',
        //   key: 'link_url',
        //   width: 120,
        //   slot: 'href'
        // },
        {
          title: '操作',
          key: 'action',
          width: 380,
          fixed: 'right',
          align: 'center',
          slot: 'operation'
        }
      ],
      balanceColumns:[
        {
          title: '#',
          slot: 'index',
          align: 'center',
          width: 60
        },
        {
          title: '时间',
          key: 'created_at',
          align: 'center',
        },
        {
          title: '金额',
          key: 'money',
          align: 'center',
        },
        {
          title: '类型',
          slot: 'scene',
          align: 'center',
        },
        {
          title: '说明',
          key: 'desc',
          align: 'center',
        }
      ],
      total: 0,
      total1:0,
      data5:[],
      balance:{
        store_id:null,
        val:'',
        type:1,
      },
      balancemodal:false,
      changemodal:false,
      editMessage:false,
      storeList: [],
      amount:null,
      recharge:null,
      consumption:null,
      storeId: '',
      configure: false,
      showModel: false,
      addModel: false,
      samemodal: false,
      balancedata:{
        store_id:null,
        page:1,
        limit:5,
        start_time:'',
        end_time:''
      },
      platform: {
        un_name: '',
        remote_domain:'',
        phone: '',
        store_mode:1,
        contacts_name: '',
        password: '',
        account: '',
        effective_time: '',
        date: ''
      },
      messageForm: {
        message_num: '',
        shop_num: '',
        store_id:''
      },
      ruleValidate: {
        account: [
          {required: true, message: '请输入账户', trigger: 'blur'},
          {type: 'string', min: 3, max: 12, message: '公告标题不得少于三个字符超过十二个字符', trigger: 'blur'}
        ],
        // password: [
        //   {required: true, message: '请输入密码', trigger: 'blur'},
        //   {type: 'string', min: 6, message: '密码不得少于六个字符', trigger: 'blur'}
        // ],
        contacts_name: [
          {required: true, message: '请输入用户姓名', trigger: 'blur'}
        ],
        un_name: [
          {required: true, message: '请输入平台名称', trigger: 'blur'}
        ],
        remote_domain:[
            {required: true, message: '请输入域名', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {type: 'string', min: 11, message: '手机号码不得少于十一个字符', trigger: 'blur'}
        ],
        store_num: [
          {required: true, message: '请输入平台数量', trigger: 'blur'}
        ]
      },
      ruleMessageForm:{
        contract_number: [
          {required: true, message: '请输入已认证合同次数', trigger: 'blur'}
        ],
        not_contract_number: [
          {required: true, message: '请输入未认证合同次数', trigger: 'blur'}
        ],
        sms_number: [
          {required: true, message: '请输入短信次数', trigger: 'blur'}
        ],
      },
      unList: [],
      storePrams: {
        is_remote:1,
        limit: 10,
        page: 1,
        name: '',
        create_time: ''
      },
      PluginList:[],
      plugin_ids:[],
      formTitle: '创建',
      headers: {},
      editId: '',
      store_id:'',
      timeChange:'0',
      formConfigure: {
        cloud_status: false,
        sms_status: false,
        oem_status: false,
        sy_status:false,
        pd_status:false,
        jf_status:false,
        synopsis: '速速云签'
      },
      jurisdictionList: []
    }
  },
  created() {
    this.getList()
    this.jurisdictionList = this.$store.state.auth
  },
  mounted() {
  },
  computed: {},
  methods: {
    changeType(e){
      this.timeChange = e
    },
    handleMessage(name){
      this.$refs[name].validate((valid) => {
        if (valid) {
          storeUpdateScnum(this.messageForm).then(res=>{
            this.$Message.success(res.msg)
            this.getList()
            this.editMessage = false
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        } else {
          return
        }
      })
    },
    resetMessage(name){
      this.$refs[name].resetFields();
    },
    same() {
      storeSynchro({store_id: this.store_id}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
        this.samemodal = false
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    samecancel(){
      this.samemodal = false
    },
    changeMsg(row){
      this.store_id = row.id
      // setTimeout(() => {
      //   this.samemodal = true
      // }, 500);
      // this.same(row)
      this.messageForm.message_num=String(row.message_num)
      this.messageForm.shop_num=String(row.shop_num)
      this.messageForm.store_id=row.id
      this.editMessage = true
    },
    getIn(row) {
      storeGetin({store_id: row.id}).then(res => {
        window.open(res.data.url)
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    getList() {
      storeList(this.storePrams).then(res => {
        this.storeList = res.data.data
        this.total = res.data.total
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    pageChange(index) {
      this.storePrams.page = index
      this.getList()
    },
    clearDel(id) {
      this.$Modal.confirm({
        title: '删除提示',
        content: '请问您是否确认删除此用户，删除后无法恢复！！！',
        onOk: () => {
          storeDel({store_id: id}).then(res => {
            this.$Message.success(res.msg)
            this.getList()
          }).catch(res => {
            this.$Message.error(res.msg)
          })
        },
        onCancel: () => {
        }
      });
    },
    searchList() {
      this.storePrams.create_time = moment(this.storePrams.create_time).format('YYYY-MM-DD')
      if (this.storePrams.create_time == 'Invalid date') {
        this.storePrams.create_time = ''
      }
      this.getList()
    },
    edit(index, type, row) {
      this.reset()
      if (type === 'edit') {
        this.formTitle = '编辑信息'
      }
      storeInfo({store_id: row.id}).then(res => {
        this.platform = res.data
        this.platform.store_id = res.data.id
        if (res.data.effective_time === '永久') {
          this.timeChange = '0'
        } else {
          this.timeChange = 'TIME'
          this.platform.date = res.data.effective_time
        }
      }).catch(res => {
        this.$Message.error(res.msg)
      })
      this.addModel = true
    },
    reset() {
      this.platform.store_id = undefined
      this.platform.un_name = ''
      this.platform.remote_domain = ''
      this.platform.phone = ''
      this.platform.contacts_name = ''
      this.timeChange = '0'
      this.platform.password = ''
      this.platform.account = ''
      this.platform.effective_time = ''
      this.platform.date = ''
    },
    handleCopy(index, row) {
      this.copyData = row
      this.copy(this.copyData)
    },
    copy(data) {
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$Message.success('复制成功');
      oInput.remove()
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.timeChange === '0') {
            this.platform.effective_time = 0
          } else {
            this.platform.effective_time = moment(this.platform.date).format('YYYY-MM-DD')
          }
          if (this.platform.store_id) {
            storeUpdate(this.platform).then(res => {
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.addModel = false
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          } else {
            storeAdd(this.platform).then(res => {
              this.$Message.success(res.msg)
              this.getList()
              this.reset()
              this.addModel = false
            }).catch(res => {
              this.$Message.error(res.msg)
            })
          }
        } else {
          this.$Message.error('请完善平台信息');
        }
      })
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    submit() {

    },
    cancel() {
      this.reset()
    },
    handleSuccess() {

    },
    handleFormatError() {

    },
    show(index) {
      console.log(index)
      this.showModel = true
    },
    addNotice(type) {
      if (type === 'add') {
        this.formTitle = '创建'
        this.platform = {
          is_remote: 1,
          un_name: '',
          remote_domain:'',
          phone: '',
          store_mode:1,
          contacts_name: '',
          password: '',
          account: '',
          effective_time: '',
          date: '',
          change: '0',
        }
      }
      this.addModel = true
    },
    changeOff(row) {
      if (this.jurisdictionList.indexOf('admin-store-disable') !== -1) {
        this.$Modal.confirm({
          title: '禁用提示',
          content: '请问您是否确认禁用此条员工账户',
          onOk: () => {
            if (row.it_disabled === 0) {
              let changeType = 1
              storeBan({store_id: row.id, type: changeType}).then(res => {
                this.getList()
                this.$Message.success(res.msg)
              }).catch(res => {
                this.$Message.error(res.msg)
              })
            }
          },
          onCancel: () => {
          }
        });
      }

    },
    changeOn(row) {
      if (this.jurisdictionList.indexOf('admin-store-disable') !== -1) {
        // this.$Modal.confirm({
        //   title: '启用提示',
        //   content: '请问您是否确认启用此条员工账户',
        //   onOk: () => {
            // if (row.it_disabled === 1) {
              storeBan({store_id: row.id, type: row.it_disabled}).then(res => {
                this.getList()
                this.$Message.success(res.msg)
              }).catch(res => {
                this.$Message.error(res.msg)
              })
            // }
          // },
          // onCancel: () => {
          // }
        // });
      }
    },
    submitConfigure() {
    },
    cancelConfigure() {

    },
    getPlugin(){
      getPlugin().then(res=>{
        this.PluginList = res.data
      })
    },
    changeConfigure(index, row) {
      this.getPlugin()
      this.plugin_ids = row.plugin_ids
      // if (row.cloud_status === 1) {
      //   this.formConfigure.cloud_status = true
      // } else {
      //   this.formConfigure.cloud_status = false
      // }
      if (row.sms_status === 1) {
        this.formConfigure.sms_status = true
      } else {
        this.formConfigure.sms_status = false
      }
      if (row.oem_status === 1) {
        this.formConfigure.oem_status = true
      } else {
        this.formConfigure.oem_status = false
      }
      this.storeId = row.id
      this.configure = true
    },
    changeFirst(e) {
      let status
      if (e === true) {
        status = 1
      } else {
        status = 0
      }
      storeSetup({type: 2, status: status, store_id: this.storeId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeSecond(e) {
      let status
      if (e === true) {
        status = 1
      } else {
        status = 0
      }
      storeSetup({type: 3, store_id: this.storeId, status: status}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    changeThird(e) {
      let status
      if (e === true) {
        status = 1
      } else {
        status = 0
      }
      storeSetup({type: 1, status: status, store_id: this.storeId}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },
    pluginunit(e,type){
      storeSetup({type: type, store_id: this.storeId, status: Number(e)}).then(res => {
        this.$Message.success(res.msg)
        this.getList()
      }).catch(res => {
        this.$Message.error(res.msg)
      })
    },

    getmtAmountList(){
      mtAmountList(this.balancedata).then(res=>{
        this.total1 = res.data.total
        this.data5 = res.data.data
      })
    },
    getamount(){
      getMtAmount({store_id:this.balancedata.store_id}).then(res=>{
        this.amount = res.data.amount
      })
    },
    balanceBtn(e){
      this.balancedata.store_id = e
      this.getmtAmountList()
      this.getamount()
      this.balancemodal = true
    },
    time1(e){
      this.balancedata.start_time = e[0]
      this.balancedata.end_time = e[1]
    },
    pageChange1(index){
      this.balancedata.page = index
      this.getmtAmountList()
    },
    searchBtn1(){
      this.getmtAmountList()
    },
    changecli(){
      this.balance.store_id = this.balancedata.store_id
      this.changemodal = true
    },
    changecancel(){
      this.changemodal = false
    },
    changeadd(){
      mtAmountHandle(this.balance).then(res=>{
        this.getmtAmountList()
        this.getList()
        this.getamount()
        this.changemodal = false
        this.balance.val = ''
        this.balance.typr = 1
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
  }
}
</script>
<style>
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center-modal .ivu-modal {
  top: 0;
}

.flex-style {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.modal-form {

}

.form-label {
  padding-right: 12px;
  width: 120px;
  text-align: right;
  font-size: 14px;
  color: #515a6e;
}

.link-href {
  color: #00a6ff;
  cursor: pointer
}

.just_center{
  display: flex;
  align-items: center;
}
.refoIcon{
  cursor: pointer;
  font-size: 20px;
  margin-left: 20px;
}

.link-href:hover {
  text-decoration: underline;
}
</style>
